'use client';

import { useState, useRef, useEffect } from 'react';
import { IScrolled } from '@/shared/types/dictionary';

export default function ScrollContainerComponent({
  data,
}: {
  data: IScrolled;
}) {
  const [activeArticleIndex, setActiveArticleIndex] = useState(0);
  const largeScreenRefs = useRef<(HTMLDivElement | null)[]>([]);
  const smallScreenRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleObserverCallback = (
      entries: IntersectionObserverEntry[],
      refs: React.MutableRefObject<(HTMLDivElement | null)[]>,
    ) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = refs.current.indexOf(entry.target as HTMLDivElement);
          if (index !== -1) {
            setActiveArticleIndex(index);
          }
        }
      });
    };

    const setupObserver = (
      refs: React.MutableRefObject<(HTMLDivElement | null)[]>,
    ) => {
      const observer = new IntersectionObserver(
        entries => handleObserverCallback(entries, refs),
        observerOptions,
      );
      refs.current.forEach(ref => {
        if (ref) observer.observe(ref);
      });
      return observer;
    };

    const largeObserver = setupObserver(largeScreenRefs);
    const smallObserver = setupObserver(smallScreenRefs);

    return () => {
      largeObserver.disconnect();
      smallObserver.disconnect();
    };
  }, []);

  return (
    <section className="pt-[7rem] bg-white">
      <div className="hidden lg:grid gap-[90px] grid-cols-[1fr_1.5fr] auto-cols-[1fr] place-items-center lg:ml-12">
        <div className="text-section w-full lg:pr-10">
          {data.list.map((article, index) => (
            <div
              key={index}
              className={`article-section h-screen flex flex-col items-center justify-center transition-transform duration-500 ${index === activeArticleIndex ? 'scale-100' : 'scale-75 opacity-70'}`}
              ref={el => {
                largeScreenRefs.current[index] = el;
              }}
            >
              <div className="ml-12">
                <div>
                  <h2 className="text-green-600 uppercase text-4xl font-bold mb-6">
                    {article.title}
                  </h2>
                  <p className="text-3xl font-bold mb-4">{article.text1}</p>
                  <p className="text-2xl sm:text-base mb-4">{article.text2}</p>
                  <p className="text-2xl sm:text-base mb-4">{article.text3}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="image-section flex flex-col justify-center items-center lg:mt-0 mt-6">
          {data.list.map((article, index) => (
            <div
              key={index}
              className={`image-container transition-transform duration-500 ${index === activeArticleIndex ? 'scale-100' : 'scale-75 opacity-70'}`}
            >
              <img
                src={article.imageUrl}
                alt={article.title}
                className="w-[640px] h-auto rounded-lg sm:w-full sm:rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="grid lg:hidden gap-6 grid-cols-1 place-items-center lg:ml-12">
        {data.list.map((article, index) => (
          <div
            key={index}
            className={`transition-transform duration-700 ease-in-out flex flex-col items-center justify-center space-y-4 ${index === activeArticleIndex ? 'scale-100' : 'scale-90 opacity-90'}`}
            ref={el => {
              smallScreenRefs.current[index] = el;
            }}
          >
            <div className="text-center mb-4">
              <h2 className="text-4xl font-bold mb-2 text-green-600 uppercase">
                {article.title}
              </h2>
            </div>

            <div className="image-container flex items-center justify-center">
              <img
                src={article.imageUrl}
                alt={article.title}
                className="max-w-full h-auto rounded-lg"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
