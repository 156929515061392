const ArrowLeft = ({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="45"
    fill="none"
    className={className}
  >
    <path
      fill={fill || '#fff'}
      d="M44 34.73v-24c0-5.523-4.477-10-10-10H10c-5.523 0-10 4.477-10 10v24c0 5.523 4.477 10 10 10h24c5.523 0 10-4.477 10-10Z"
    />
    <path
      fill={fill || '#3B4856'}
      d="M7.865 21.429c-.167.174-.298.38-.385.605a1.723 1.723 0 0 0 0 1.393c.087.225.218.43.385.605l7.333 7.333a1.84 1.84 0 1 0 2.604-2.603l-4.217-4.198h21.248a1.834 1.834 0 0 0 0-3.667H13.585l4.217-4.198a1.84 1.84 0 1 0-2.604-2.604L7.865 21.43Z"
    />
  </svg>
);

export { ArrowLeft };
