'use client';

import SectionPhoto from '@/public/main-page/Section.png';
import { Carousel } from 'flowbite-react';
import { ITextCarousel } from '@/shared/types/dictionary';
import { generateKey } from '@/shared/utils/generate-key';

export default function TextCarouselComponent({
  data,
}: {
  data: ITextCarousel;
}) {
  const coachData = data.list.filter(item => item.owner === 'Coach');
  const golferData = data.list.filter(item => item.owner === 'Golfer');

  const combinedData = [];

  for (let i = 0; i < Math.max(coachData.length, golferData.length); i++) {
    if (coachData[i]) combinedData.push(coachData[i]);
    if (golferData[i]) combinedData.push(golferData[i]);
  }

  const pages = [];
  for (let i = 0; i < combinedData.length; i += 3) {
    pages.push(combinedData.slice(i, i + 3));
  }

  return (
    <section className="w-full">
      <div className="bg-white">
        <div
          className="relative w-full h-[324px] md:h-[400px] lg:h-[558px] bg-cover bg-center"
          style={{ backgroundImage: `url(${SectionPhoto.src})` }}
        >
          <Carousel
            className="h-full"
            leftControl={<span className="invisible" />}
            rightControl={<span className="invisible" />}
          >
            {pages.map((page, pageIndex) => (
              <div
                key={generateKey(`page-${pageIndex}`)}
                className="flex flex-col md:flex-row items-center justify-center h-full w-full px-6"
              >
                {page.map((el, index) => (
                  <div
                    key={generateKey(el.owner + index)}
                    className="flex flex-col bg-black bg-opacity-60 text-white p-8 rounded-lg justify-center items-center text-center w-full max-w-[558px] h-2/5 mx-2"
                  >
                    <p className="mb-4 text-lg font-medium">
                      {el.h1 || el.h2 || el.h3}
                    </p>
                    <p className="text-sm font-semibold mt-2">- {el.owner}</p>
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
