const ArrowRight = ({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    className={className}
  >
    <path
      fill={fill || '#fff'}
      d="M.81 10.73v24c0 5.523 4.477 10 10 10h24c5.523 0 10-4.477 10-10v-24c0-5.523-4.477-10-10-10h-24c-5.523 0-10 4.477-10 10Z"
    />
    <path
      fill={fill || '#3B4856'}
      d="M36.945 24.031c.167-.174.298-.38.385-.605a1.723 1.723 0 0 0 0-1.393 1.833 1.833 0 0 0-.385-.605l-7.333-7.333a1.84 1.84 0 1 0-2.604 2.603l4.217 4.198H9.977a1.833 1.833 0 0 0 0 3.667h21.248l-4.217 4.198a1.84 1.84 0 1 0 2.604 2.604l7.333-7.334Z"
    />
  </svg>
);

export { ArrowRight };
